import { getLanguage, getPageHtml, getPageProps } from '../util/PageUtil';
import { wrapper } from '../store/store';
import { setLanguage } from '../store/languageSlice';
import { setHost } from '../store/hostSlice';
import { parseCookies, setCookie } from 'nookies';

function DefaultPage(props) {
  return getPageHtml(props);
}

// This gets called on every request
// export async function getServerSideProps(context) {
//     return getPageProps(context);
// }
export const getServerSideProps = wrapper.getServerSideProps(
  (store) => async (context) => {
    const { req, res } = context;
    let host;

    res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
    );

    if (req) {
      host = req.headers.host;
    }
    const cookies = parseCookies({ req });
    const adminCookie = cookies.adminUser;

    let lang = getLanguage(host);
    store.dispatch(setHost(host));
    store.dispatch(setLanguage(lang));

    const pageProps = await getPageProps(context, store, adminCookie);
    const hasJpParam = req.url.includes('&jp');

    // Check the condition for a custom 404
    const isCustom404 =
      pageProps &&
      pageProps.props &&
      pageProps.props.data &&
      pageProps.props.data.field_error_codes &&
      pageProps.props.data.field_error_codes.length > 0 &&
      pageProps.props.data.field_error_codes[0].value === '1';

    // Check for the Asia region and &jp parameter
    const shouldRedirectToHome = lang === 'asia' && hasJpParam;

    // If it's a custom 404 and meets the conditions for redirection
    if (isCustom404) {
      if (shouldRedirectToHome) {
        return {
          redirect: {
            destination: '/',
            permanent: false,
          },
        };
      }
      setCookie(context, 'custom404', JSON.stringify(pageProps.props.data), {
        maxAge: 30 * 24 * 60 * 60, // 30 days
        path: '/',
      });
      return {
        notFound: true,
      };
    }
    return pageProps;
  }
);

export default DefaultPage;
